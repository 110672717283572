import { Button, Dialog, DialogTitle, TextField } from "@mui/material";
import axios from "./axios.js";
import { useState } from "react";

export const ChangePriceDialog = (props) => {
  const { onClose, open, oldPrice, photo, bouquetId } = props;

  const [newPrice, setNewPrice] = useState(null);

  const handleClose = () => {
    setNewPrice(null);
    onClose(oldPrice);
  };

  const handlePriceChange = async () => {
    try {
      if (newPrice && window.confirm("Ви впевнені, що хочете змінити ціну?")) {
        console.log(newPrice);
        await axios.patch(`/bouquets/${bouquetId}`, {
          price: newPrice,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setNewPrice(null);
      handleClose();
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Стара ціна: {oldPrice}</DialogTitle>

      <img
        src={`https://app.vazaflower.com${photo}`}
        alt="bouquet"
        style={{ maxWidth: "350px", margin: "15px", borderRadius: "5px" }}
      />

      <TextField
        id="price"
        label="Вкажи нову ціну"
        variant="outlined"
        value={newPrice}
        onChange={(e) => setNewPrice(e.target.value)}
        sx={{ marginInline: "10px" }}
      />
      <Button
        variant="contained"
        onClick={handlePriceChange}
        sx={{ margin: "10px" }}
      >
        Змінити ціну
      </Button>
    </Dialog>
  );
};
