import s from "./Header.module.scss";
import { Link } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CollectionsIcon from "@mui/icons-material/Collections";
export const Header = () => {
  return (
    <>
      <header className={s.header}>
        <img className={s.header_background} src="/header-bg.jpeg" alt="" />

        <div className={s.neon_text}>
          <h1 className={s.neon_title}>VAZA</h1>
          <h3 className={s.neon_discr}>flower shop</h3>
        </div>
      </header>

      <Grid
        container
        spacing={2}
        sx={{
          marginBottom: "15px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Link underline="none" color="primary" to="/bouquets">
            <Button
              color="primary"
              variant="outlined"
              size="small"
              startIcon={<StorefrontOutlinedIcon />}
            >
              Вітрина
            </Button>
          </Link>
        </Grid>

        <Grid item>
          <Link underline="none" color="primary" to="/create">
            <Button
              color="primary"
              variant="outlined"
              size="small"
              startIcon={<AddBoxOutlinedIcon />}
            >
              Створити
            </Button>
          </Link>
        </Grid>

        <Grid item>
          <Link underline={"none"} color="primary" to="/dashboard">
            <Button
              color="primary"
              variant="outlined"
              size="small"
              startIcon={<AttachMoneyOutlinedIcon />}
            >
              Замовлення
            </Button>
          </Link>
        </Grid>

        <Grid item>
          <Link underline={"none"} color="primary" to="/gallery">
            <Button
              color="primary"
              variant="outlined"
              size="small"
              startIcon={<CollectionsIcon />}
            >
              Галерея
            </Button>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};
