import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, TextField } from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import StoreIcon from "@mui/icons-material/Store";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import Typography from "@mui/material/Typography";
import { Navigate } from "react-router-dom";

import { useEffect, useState, useContext } from "react";
import axios from "./axios.js";
import { AuthContext } from "../App.jsx";

export const Dashboard = () => {
  const [orders, setOrders] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [fetching, setFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { isAuth } = useContext(AuthContext);

  const scrollHandler = (e) => {
    const scrollHeight = e.target.documentElement.scrollHeight;
    const scrollTop = e.target.documentElement.scrollTop;
    const innerHeight = window.innerHeight;

    if (scrollHeight - (scrollTop + innerHeight) < 100) {
      setFetching(true);
    }
  };

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    if (!token) {
      return <Navigate to="/login" replace={true} />;
    }
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);
    return function () {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  useEffect(() => {
    try {
      async function fetchOrders() {
        if (fetching) {
          const token = window.localStorage.getItem("token");
          const { data } = await axios.get(
            `/dashboard?page=${currentPage}&size=3`,
            { headers: { authorization: token } }
          );

          setOrders((prev) => [...prev, ...data.reverse()]);
          setCurrentPage((prev) => prev + 1);
        }
      }
      fetchOrders();
    } catch (error) {
      console.error(error);
      console.error("Не удалось загрузить заказы");
    } finally {
      setFetching(false);
    }
  }, [fetching, currentPage]);

  const bouquetReturn = async (bouquetId) => {
    try {
      setOrders((prevOrders) =>
        prevOrders.filter((order) => order.bouquetId !== bouquetId)
      );
      await axios.patch(`/dashboard/${bouquetId}`);
    } catch (error) {
      console.error(error);
      alert("Не удалось загрузить заказы");
    }
  };

  const orderCheck = (orderId) => {
    async function orderChecking(orderId) {
      try {
        await axios.patch(`/create/${orderId}`);

        const { newOrders } = await axios.get("/dashboard");

        setOrders(newOrders);
      } catch (error) {
        console.error(error);
        alert("Не удалось загрузить заказы");
      }
    }
    orderChecking(orderId);
  };

  const filteredOrders = orders.filter((order) =>
    order.orderPhoneNumber.toString().includes(searchValue.toString())
  );

  return isAuth ? (
    <>
      <TextField
        id="search"
        label="Поиск по номеру"
        variant="outlined"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        style={{ margin: "15px 25px" }}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 400 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography
                  style={{ fontSize: "12px", textShadow: "0 1px 1px #000" }}
                >
                  <b>Телефон клієнта</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{ fontSize: "12px", textShadow: "0 1px 1px #000" }}
                >
                  <b>Спосіб зв'язку</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{ fontSize: "12px", textShadow: "0 1px 1px #000" }}
                >
                  <b>Фото</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{ fontSize: "12px", textShadow: "0 1px 1px #000" }}
                >
                  <b>Доставка</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{ fontSize: "12px", textShadow: "0 1px 1px #000" }}
                >
                  <b>Виконано</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{ fontSize: "12px", textShadow: "0 1px 1px #000" }}
                >
                  <b>Адреса доставки</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{ fontSize: "12px", textShadow: "0 1px 1px #000" }}
                >
                  <b>Телефон отримувача</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{ fontSize: "12px", textShadow: "0 1px 1px #000" }}
                >
                  <b>Дата та час</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{ fontSize: "12px", textShadow: "0 1px 1px #000" }}
                >
                  <b>Ціна</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{ fontSize: "12px", textShadow: "0 1px 1px #000" }}
                >
                  <b>Номер замовлення</b>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{ fontSize: "12px", textShadow: "0 1px 1px #000" }}
                >
                  <b>Повернути на вітрину</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOrders.map((order) => {
              let phone = order.orderPhoneNumber;
              return (
                <TableRow key={order._id}>
                  <TableCell align="center">
                    <a href={"tel:+" + { phone }}> +{phone}</a>
                  </TableCell>
                  <TableCell align="center">{order.contactType}</TableCell>
                  <TableCell padding="none" align="center">
                    <LazyLoadImage
                      src={`https://app.vazaflower.com${order.imageUrl}`}
                      alt="bouquet_image"
                      width={100}
                      height={100}
                      effect="blur"
                    />
                  </TableCell>
                  <TableCell align="center">
                    {order.isDelivery ? (
                      <LocalShippingIcon color="success" fontSize="large" />
                    ) : (
                      <StoreIcon color="primary" fontSize="large" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Button onClick={() => orderCheck(order._id)}>
                      {order.isComplete ? (
                        <AssignmentTurnedInIcon
                          color="success"
                          fontSize="large"
                        />
                      ) : (
                        <HelpCenterIcon color="primary" fontSize="large" />
                      )}
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    {order.orderDeliveryAddress}
                  </TableCell>
                  <TableCell align="center">
                    {order.orderDeliveryNumber}
                  </TableCell>
                  <TableCell align="center">
                    {new Date(order.createdAt).toLocaleString([], {
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </TableCell>
                  <TableCell align="center">{order.price}</TableCell>
                  <TableCell align="center">{order.orderId}</TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => bouquetReturn(order.bouquetId)}
                      color="error"
                    >
                      <ChangeCircleIcon fontSize="large" />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) : (
    <Navigate to="/login" replace={true} />
  );
};
