import React, { useState } from "react";

import { Header } from "./components/Header";
import { Dashboard } from "./components/Dashboard";
import { CreateBouquet } from "./components/CreateBouquet";
import { Bouquets } from "./components/Bouquets";

import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import { Login } from "./components/Login";
import Gallery from "./components/Gallery";

export const AuthContext = React.createContext();

function App() {
  const [isAuth, setIsAuth] = useState(false);
  return (
    <>
      <AuthContext.Provider value={{ isAuth, setIsAuth }}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/create" element={<CreateBouquet />} />
          <Route path="/bouquets" element={<Bouquets />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
      </AuthContext.Provider>
    </>
  );
}

export default App;
