import { useState, useContext } from "react";
import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Alert,
} from "@mui/material";
import MonochromePhotosIcon from "@mui/icons-material/MonochromePhotos";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import { AuthContext } from "../App.jsx";
import "../index.scss";
import axios from "./axios.js";
import { Navigate } from "react-router-dom";

export const CreateBouquet = () => {
  const [flowers, setFlowers] = useState([]);
  const [size, setSize] = useState("");
  const [price, setPrice] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isCreate, setIsCreate] = useState(false);
  const [isImage, setIsImage] = useState(false);

  const { isAuth } = useContext(AuthContext);

  const cleanForm = async () => {
    setFlowers([]);
    setSize("");
    setPrice("");
    setImageUrl("");
    setIsImage(false);
  };

  const createHandler = async () => {
    try {
      const newBouquet = { flowers, size, price, imageUrl };
      const token = window.localStorage.getItem("token");
      await axios.post("/create", newBouquet, {
        headers: { authorization: token },
      });
      setIsCreate(true);
      cleanForm();
    } catch (error) {
      console.error(error);
      alert("Не удалось создать букет");
    }
  };

  const handleChangeFile = async (event) => {
    try {
      const formData = new FormData();
      const imageFile = event.target.files[0];
      formData.append("image", imageFile);
      const { data } = await axios.post("/uploads", formData);

      setImageUrl(data.url);
      setIsImage(true);
    } catch (error) {
      console.error(error);
      alert("Не удалось загрузить изображение:" + error);
    }
  };

  return isAuth ? (
    <FormControl
      formEncType="multipart/form-data"
      className="create_form"
      fullWidth
    >
      <div className="create">
        <h1 className="create_title">Створення букету</h1>
        <TextField
          className="create_form_flowers"
          id="standard-basic"
          label="Квіти"
          variant="filled"
          value={flowers}
          onChange={(e) => setFlowers(e.currentTarget.value)}
        />
        <FormControl className="create_form" fullWidth>
          <InputLabel id="select_size">Розмір</InputLabel>
          <Select
            className="create_form_size"
            labelId="select_size"
            id="select_size_input"
            value={size}
            label="Розмір"
            onChange={(e) => setSize(e.target.value)}
          >
            <MenuItem value="XS">XS</MenuItem>
            <MenuItem value="S">S</MenuItem>
            <MenuItem value="M">M</MenuItem>
            <MenuItem value="L">L</MenuItem>
            <MenuItem value="XL">XL</MenuItem>
          </Select>
        </FormControl>
        <TextField
          className="create_form_price"
          id="standard-basic"
          label="Ціна"
          variant="filled"
          value={price}
          onChange={(e) => setPrice(e.currentTarget.value)}
        />
        <Button
          formEncType="multipart/form-data"
          variant="contained"
          component="label"
          endIcon={<MonochromePhotosIcon />}
        >
          Завантажити фото
          <input
            hidden
            accept="image/*"
            formEnctype="multipart/form-data"
            type="file"
            alt="bouquet_photo"
            onChange={handleChangeFile}
          />
        </Button>
        {isImage ? (
          <Alert severity="success">Фото завантажено успішно!</Alert>
        ) : null}

        <Button
          variant="outlined"
          onClick={cleanForm}
          startIcon={<DeleteIcon />}
        >
          Очистити форму
        </Button>
        <Button
          onClick={createHandler}
          color="success"
          variant="contained"
          endIcon={<SendIcon />}
        >
          Створити букет
        </Button>
        {isCreate ? (
          <Alert
            style={{ position: "absolute", bottom: "-3px", width: "290px" }}
            onClose={() => setIsCreate(false)}
          >
            Букет створено успішно!
          </Alert>
        ) : (
          ""
        )}
      </div>
    </FormControl>
  ) : (
    <Navigate to="/login" replace={true} />
  );
};
