import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import ArchiveIcon from "@mui/icons-material/Archive";
import { Navigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useEffect } from "react";
import axios from "./axios.js";
import { useState, useContext } from "react";
import { AuthContext } from "../App.jsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import CircularProgress from "@mui/material/CircularProgress";
import { ChangePriceDialog } from "./ChangePriceDialog.jsx";
import EditIcon from "@mui/icons-material/Edit";
export const Bouquets = () => {
  const [availableBouquets, setAvailableBouquets] = useState([]);
  const { isAuth } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [selectedBouquet, setSelectedBouquet] = useState(null);

  const handleClose = (price) => {
    setAvailableBouquets((prevBouquets) =>
      prevBouquets.map((prevBouquet) =>
        prevBouquet._id === selectedBouquet._id
          ? { ...prevBouquet, price: price }
          : prevBouquet
      )
    );

    setOpen(false);
  };

  useEffect(() => {
    try {
      async function fetchBouquets() {
        const token = window.localStorage.getItem("token");
        const { data } = await axios.get("/bouquets", {
          headers: { authorization: token },
        });
        setAvailableBouquets(data);
      }
      fetchBouquets();
    } catch (error) {
      console.error(error);
      alert("Не удалось загрузить букеты");
    }
  }, []);

  const deleteBouquet = (id) => {
    if (window.confirm("Точно-точно видалити букет?")) {
      axios.delete(`/bouquets/${id}`);
      setAvailableBouquets((prevBouquets) =>
        prevBouquets.filter((bouquet) => bouquet._id !== id)
      );
    }
  };

  const updateBouquet = (id) => {
    if (window.confirm("Букет продано?")) {
      axios.patch(`/shop/${id}`);
      setAvailableBouquets((prevBouquets) =>
        prevBouquets.filter((bouquet) => bouquet._id !== id)
      );
    }
  };

  const handleBouquetSelect = (bouquet) => {
    setSelectedBouquet(bouquet);
    setOpen(true);
  };

  return isAuth ? (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell
              style={{ fontSize: "12px", textShadow: " 0 1px 1px #000" }}
              align="center"
            >
              <b> Створено</b>
            </TableCell>
            <TableCell
              style={{ fontSize: "12px", textShadow: " 0 1px 1px #000" }}
              align="center"
            >
              <b> Фото букета</b>
            </TableCell>
            <TableCell
              style={{ fontSize: "12px", textShadow: " 0 1px 1px #000" }}
              align="center"
            >
              <b> До галереї</b>
            </TableCell>
            <TableCell
              style={{ fontSize: "12px", textShadow: " 0 1px 1px #000" }}
              align="center"
            >
              <b> Ціна</b>
            </TableCell>
            <TableCell
              style={{ fontSize: "12px", textShadow: " 0 1px 1px #000" }}
              align="center"
            >
              <b> Видалити букет</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {availableBouquets &&
            availableBouquets.map((bouquet) => {
              return (
                <TableRow
                  key={bouquet._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    padding="none"
                    align="center"
                    sx={{ textShadow: " 0 1px 2px #000", fontSize: "12px" }}
                  >
                    {new Date(bouquet.createdAt).toLocaleString([], {
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <LazyLoadImage
                      src={`https://app.vazaflower.com${bouquet.imageUrl}`}
                      alt="bouquet photo"
                      width={100}
                      height={100}
                      effect="blur"
                      placeholder={<CircularProgress color="success" />}
                    />
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <Button
                      formEncType="multipart/form-data"
                      onClick={() => updateBouquet(bouquet._id)}
                      color="primary"
                      size="large"
                    >
                      <ArchiveIcon fontSize="large" />
                    </Button>
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    sx={{ textShadow: " 0 1px 2px #000", fontSize: "14px" }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => handleBouquetSelect(bouquet)}
                      startIcon={<EditIcon />}
                    >
                      {bouquet.price} грн
                    </Button>
                    {selectedBouquet && (
                      <ChangePriceDialog
                        open={open}
                        onClose={handleClose}
                        oldPrice={selectedBouquet.price}
                        bouquetId={selectedBouquet._id}
                        photo={selectedBouquet.imageUrl}
                      />
                    )}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <Button
                      formEncType="multipart/form-data"
                      onClick={() => deleteBouquet(bouquet._id)}
                      color="error"
                      size="large"
                    >
                      <DeleteForeverIcon fontSize="large" />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Navigate to="/login" replace={true} />
  );
};
