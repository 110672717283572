import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { useContext } from "react";
import LockIcon from "@mui/icons-material/Lock";
import axios from "./axios.js";
import { AuthContext } from "../App.jsx";
export const Login = () => {
  const { isAuth, setIsAuth } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      login: "",
      password: "",
    },
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    try {
      const adminData = await axios.post("/login", data);
      const adminToken = adminData.data.token;
      window.localStorage.setItem("token", adminToken);

      if (adminToken) {
        setIsAuth(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return !isAuth ? (
    <Grid container>
      <Paper sx={{ margin: "0 auto", paddingInline: "15px" }}>
        <Typography
          variant="h2"
          sx={{ margin: "5px auto", textAlign: "center" }}
        >
          Авторизація <LockIcon fontSize="large" color="success" />
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            sx={{ margin: "15px auto" }}
            type="text"
            {...register("login", { required: "Вкажи логін" })}
            error={Boolean(errors.login?.message)}
            helperText={errors.login?.message}
            label="Login"
            fullWidth
          />
          <TextField
            sx={{ margin: "15px auto" }}
            type="password"
            {...register("password", { required: "Вкажи password" })}
            error={Boolean(errors.password?.message)}
            helperText={errors.password?.message}
            label="Password"
            fullWidth
          />
          <Paper
            sx={{
              margin: "15px auto",
              paddingInline: "15px",
              textAlign: "center",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              sx={{ margin: "15px auto" }}
            >
              log in
            </Button>
          </Paper>
        </form>
      </Paper>
    </Grid>
  ) : (
    <Navigate to="/create" replace={true} />
  );
};
